exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-colabordaciones-en-js": () => import("./../../../src/pages/colabordaciones.en.js" /* webpackChunkName: "component---src-pages-colabordaciones-en-js" */),
  "component---src-pages-colabordaciones-es-js": () => import("./../../../src/pages/colabordaciones.es.js" /* webpackChunkName: "component---src-pages-colabordaciones-es-js" */),
  "component---src-pages-colabordaciones-js": () => import("./../../../src/pages/colabordaciones.js" /* webpackChunkName: "component---src-pages-colabordaciones-js" */),
  "component---src-pages-contacto-en-js": () => import("./../../../src/pages/contacto.en.js" /* webpackChunkName: "component---src-pages-contacto-en-js" */),
  "component---src-pages-contacto-es-js": () => import("./../../../src/pages/contacto.es.js" /* webpackChunkName: "component---src-pages-contacto-es-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-exposiciones-en-js": () => import("./../../../src/pages/exposiciones.en.js" /* webpackChunkName: "component---src-pages-exposiciones-en-js" */),
  "component---src-pages-exposiciones-es-js": () => import("./../../../src/pages/exposiciones.es.js" /* webpackChunkName: "component---src-pages-exposiciones-es-js" */),
  "component---src-pages-exposiciones-js": () => import("./../../../src/pages/exposiciones.js" /* webpackChunkName: "component---src-pages-exposiciones-js" */),
  "component---src-pages-index-en-js": () => import("./../../../src/pages/index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-es-js": () => import("./../../../src/pages/index.es.js" /* webpackChunkName: "component---src-pages-index-es-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-nosotras-en-js": () => import("./../../../src/pages/nosotras.en.js" /* webpackChunkName: "component---src-pages-nosotras-en-js" */),
  "component---src-pages-nosotras-es-js": () => import("./../../../src/pages/nosotras.es.js" /* webpackChunkName: "component---src-pages-nosotras-es-js" */),
  "component---src-pages-nosotras-js": () => import("./../../../src/pages/nosotras.js" /* webpackChunkName: "component---src-pages-nosotras-js" */),
  "component---src-pages-obras-js": () => import("./../../../src/pages/obras.js" /* webpackChunkName: "component---src-pages-obras-js" */),
  "component---src-pages-videos-en-js": () => import("./../../../src/pages/videos.en.js" /* webpackChunkName: "component---src-pages-videos-en-js" */),
  "component---src-pages-videos-es-js": () => import("./../../../src/pages/videos.es.js" /* webpackChunkName: "component---src-pages-videos-es-js" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-exposition-page-js": () => import("./../../../src/templates/ExpositionPage.js" /* webpackChunkName: "component---src-templates-exposition-page-js" */),
  "component---src-templates-obra-page-js": () => import("./../../../src/templates/ObraPage.js" /* webpackChunkName: "component---src-templates-obra-page-js" */),
  "component---src-templates-videos-page-js": () => import("./../../../src/templates/VideosPage.js" /* webpackChunkName: "component---src-templates-videos-page-js" */)
}

